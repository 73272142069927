<template>
  <div>
    <div
      ref="myChart"
      class="box"
      :style="{ width: chartWidth + '%', height: chartHeight + 'vh' }"
    ></div>
  </div>
</template>
<script>
import * as echarts from "echarts/lib/echarts";
import 'echarts/lib/component/grid'
export default {
  props: {
    chartWidth: {
      type: Number,
      required: true,
    },
    chartHeight: {
      type: Number,
      required: true,
    },
    pieChart: {
        type: Boolean,
        required: false,
    },
    chart: {
        type: Boolean,
        required: false,
    },
    data: {
        type: Array,
        required: false,
    },
    name: {
        type: String,
        required: false,
    }
  },
  watch: {
    data: function() {
      if(this.chart) {
          this.barChart()
      }
    },
    deep: true,
    immediate: true,
    "$route.path": {
      handler(newValue,oldValue){
      let myChart = echarts.getInstanceByDom(
        this.$refs.myChart
      );
      myChart.resize()
      },
      deep: true
    }
  },
  data() {
    return {};
  },
  mounted() {
      if(this.chart) {
          this.barChart()
      }
  },
  methods: {
    barChart() {
      let max = 0
      let maxList = []
      let YList = []
      let YList2 = []
      if(this.data.length>0) {
        YList = []
        YList2 = []
        this.data.forEach(item => {
          if(item.value > max) {
            max = item.value
          }
          let str = item.value + '/件'
          YList2.push(item.name)
          YList.push(str)
        })
        maxList = this.data.map(item => {
          var num = Math.ceil(max/4)
          return max+num
        })
      }
      let option = {
        // color: ['#fd1726','#f98d39','#50baec','#4386ff'],
        title: {
          
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },

        },
        legend: {
            show: false,
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "0%",
          top: "15%",
          containLabel: true,
        },
        xAxis: {
          // type: "value",
          // boundaryGap: [0, 0.01],
          show: false,
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: YList2.length>0 ? YList2 : ["xx养老机构", "xx养老机构", "xx养老机构", "xx养老机构"],
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
              color: '#fff',
              padding: [0,0,0,0]
            },
            axisTick: {
              show:false,
            },
          },
          {
            type: "category",
            inverse: true,
            data: YList.length>0 ? YList : ["12/件", "11/件", "9/件", "8/件"],
            axisLine: {
              show: false,
            },
            axisLabel: {
              color: '#fff',
              padding: [0,0,0,0]
            },
            axisTick: {
              show:false,
            },
          },
        ],
        series: [
          {
            name: '',
            type: "bar",
            data: this.data ? this.data : [200,150,100,100],
            barMaxWidth: 5,
            itemStyle: {
                borderRadius: [5,5,5,5],
                // color: 'rgb(72, 167, 247)',
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: "#48a7f7",
                  },
                  {
                    offset: 1,
                    color: "rgba(72, 167, 247,.2)",
                  },
                ]),
            },
          },
          {
            name:'',
            type: "bar",
            data: maxList.length>0 ? maxList : [400,400,400,400],
            barMaxWidth: 10,
            label: {
              show: true,
              formatter: '{b}',
              position: ['0%','-180%'],
              color: '#fff',
            },
            itemStyle: {
                borderRadius: [5,5,5,5],
                color: 'rgba(14, 134, 255,.2)'
            },
            tooltip: {
              show: false,
            },
          },
        ],
      };
        window.addEventListener("resize", () => {
        myChart.resize();
      });
      let myChart = echarts.getInstanceByDom(this.$refs.myChart);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.myChart);
      }
      myChart.setOption(option);
    },
  },
};
</script>