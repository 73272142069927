<template>
	<div class="remark-main" v-show="show">
		<span class="border-color"></span>
		<span class="border-color"></span>
		<span class="border-color"></span>
		<span class="border-color"></span>
		<div class="close" @click="closePop">
			<i class="el-icon-close"></i>
		</div>
		<div class="title">
			{{info.name}}
		</div>
		<div class="info">
			联系人：{{info.guardianName}}
		</div>
		<div class="info">
			联系人电话：{{info.guardianPhone}}
		</div>
		<div class="info">
			地址：{{info.address}}
		</div>
		<!-- 切换 -->
		<div class="option">
			<div class="option-item option-item-active">设备预警</div>
		</div>
		<div class="option-one">
			<el-table ref="remarkTable" :data="warningHandingDetails" :max-height="300">
				<el-table-column show-overflow-tooltip prop="alarmTime" align="center" label="时间" width="150">
				</el-table-column>
				<el-table-column align="center" prop="devcieType" label="设备" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="alarmType" align="center" label="预警类型" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="processStatus" align="center" label="处理状态" width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.processStatus==0">
							<span v-if="scope.row.isResume==1">已恢复</span>
							<span v-else>待处理</span>
						</span>
						<span v-if="scope.row.processStatus==1">暂不处理</span>
						<span v-if="scope.row.processStatus==2">处理中</span>
						<span v-if="scope.row.processStatus==3">已处理</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	import {
		getPersonEventList
	} from "../api/oldmanApi"
	export default {
		props: {
			show: {
				type: Boolean,
				required: true,
			}
		},
		data() {
			return {
				warningType: '',
				warningHandingDetails: [],
				oldList: [],
				pageNum: 1,
				pageSize: 10,
				totalNum: 0,
				info: {},
				// 弹窗类型
				type: 1, // 1.敬老院 2.社区
				id: 0,
			}
		},
		watch: {

		},
		mounted() {
			let that = this
			this.$refs.remarkTable.bodyWrapper.addEventListener('scroll', function() {
				let clientHeight = this.clientHeight
				let scrollTop = Math.ceil(this.scrollTop)
				let scrollHeight = this.scrollHeight
				if (clientHeight + scrollTop === scrollHeight) {
					// 滚动到最底时，加载下一页
					// if (that.total <= that.index && !this.popover) return
					// that.index += 1
					// 滚动条回滚至顶部
					// this.$refs.screenTable.bodyWrapper.scrollTop = 0
					if (that.pageNum < that.totalNum) {
						that.pageNum += 1
						that.getPersonEventList()
					}
				}
			}, true)
		},
		methods: {
			init(info) {
				this.info = info
				this.type = info.type
				this.pageNum = 1
				this.id = info.id
				this.getPersonEventList()
			},
			// 获取预警信息
			getPersonEventList() {
				getPersonEventList({
					personId: this.info.personId,
					page: 1,
					limit: this.pageSize
				}).then(res => {
					if (res.code == 0) {
						if (this.pageNum == 1) this.warningHandingDetails = []
						this.warningHandingDetails = this.warningHandingDetails.concat(res.page.list)
						this.totalNum = res.page.totalPage
					} else {
						this.warningHandingDetails = []
					}
				})
			},
			closePop() {
				this.$emit('update:show', false)
			},
			option() {},
			filterHandler(value, row, column) {
				const property = column['property'];
				return row[property] === value;
			},
			optioncommunity() {},
		},
	}
</script>
<style lang="scss">
	.remark-main {
		width: 500px;
		// height: 100px;
		background: rgba(1, 31, 17, .8);
		position: absolute;
		border: 1px solid #2BCEFD;
		box-sizing: border-box;
		padding: 10px;
		top: 200px;
		left: 712px;
		z-index: 999;

		.border-color:nth-child(1),
		.border-color:nth-child(2),
		.border-color:nth-child(3) {
			position: absolute;
			// top: 0;
			left: -2px;
			display: block;
			// height: 60px;
			width: 4px;
			background: #2BCEFD;
		}

		.border-color:nth-child(1) {
			height: 60px;
			top: 0;
		}

		.border-color:nth-child(2) {
			height: 30px;
			top: 90px;
		}

		.border-color:nth-child(3) {
			height: 60px;
			bottom: 0;
		}

		.border-color:nth-child(4) {
			display: block;
			background: #2BCEFD;
			height: 4px;
			width: 30px;
			position: absolute;
			right: 0;
			bottom: 0;
		}

		.close {
			display: block;
			position: absolute;
			background: rgba(40, 70, 140, .8);
			top: -20px;
			right: -2px;
			color: white;
			cursor: pointer;
		}

		.title {
			width: 80%;
			background: rgb(15, 135, 255);
			color: white;
			text-align: left;
			box-sizing: border-box;
			padding: 5px;
			padding-left: 10px;
		}

		.info {
			width: 100%;
			color: white;
			font-size: 12px;
			box-sizing: border-box;
			padding: 10px 10px 0 10px;
			// padding-left: ;
			text-align: left;
		}

		.option {
			display: flex;
			margin-top: 20px;

			.option-item {
				font-size: 12px;
				color: #fff;
				padding: 5px 0px;
				background: rgba(0, 131, 255, .23);
				width: 60px;
				text-align: center;
				cursor: pointer;
			}

			.option-item-active {
				border: 2px solid rgba(15, 135, 255, .8);
			}
		}

		.option-one {
			display: flex;
			justify-content: space-evenly;
			width: 100%;
			height: calc(100% - 40px);
			box-sizing: border-box;
			flex-wrap: wrap;
			padding: 30px 10px;
			padding-bottom: 30px;
			background: rgba(0, 131, 255, .23);

			.item-main-top {
				width: 100%;
				box-sizing: border-box;
				padding: 0 20px;
				display: flex;
				height: 30px;
				justify-content: space-between;

				.item-main-top-item {
					width: 24%;
					background: rgba(0, 86, 255, .6);
					font-size: 12px;
					display: flex;
					align-items: center;
					color: white;
					justify-content: center;

					.el-select {
						.el-input {
							.el-input__inner {
								background: none;
								color: white;
								text-align: center;
								border: none;
								padding-left: 5px;
								padding-right: 15px;
								font-size: 12px;
							}

							.el-input__suffix {
								right: -2px;
							}
						}

						input::-webkit-input-placeholder {
							color: #fff;
						}

						input::-moz-placeholder {
							color: #fff;
						}

						input:-ms-input-placeholder {
							color: #fff;
						}
					}

					.el-date-editor {
						.el-range-separator {
							color: #fff;
						}
					}
				}
			}

		}

	}

	.device-image {
		width: 80px;
		height: 80px;
	}

	.el-table__header-wrapper {
		padding: 0 !important;
		line-height: 30px !important;
	}
</style>
