<template>
    <div class="remark-main" v-show="show">
        <span class="border-color"></span>
        <span class="border-color"></span>
        <span class="border-color"></span>
        <span class="border-color"></span>
        <div class="close" @click="closePop">
            <i class="el-icon-close"></i>
        </div>
        <div class="title">
            {{info.name}}
        </div>
        <div class="info">
            {{type == 1 ? '在院老人' : '老人数量'}}：{{info.people}}
        </div>
        <div class="info">
            {{type == 1 ? '机构床位' : '村社数量'}}：{{type == 1 ? info.bedNum : info.communityNum}}
        </div>
        <div class="info">
            机构电话：{{info.phone}}
        </div>
        <div class="info">
            机构地址：{{info.address}}
        </div>
        <div class="info">
            设备在线率：{{info.onlineRate}}
        </div>
        <!-- 切换 -->
        <div class="option">
            <div :class="optionType == 1 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(1)">预警记录</div>
            <div :class="optionType == 2 ? 'option-item option-item-active' : 'option-item'" @click="optionClick(2)">老人</div>
        </div>
        <div v-show="optionType == 1" class="option-one">
            <el-table
				ref="remarkTable"
				:data="warningHandingDetails"
				:max-height="300"
                @row-click="rowClick"
				style="width: 100%">
				<el-table-column 
                show-overflow-tooltip
					prop="alarmTime"
					align="center"
					label="时间"
                    :filters="[{text: '2016-05-01', value: '2016-05-01'}, {text: '2016-05-02', value: '2016-05-02'}, {text: '2016-05-03', value: '2016-05-03'}, {text: '2016-05-04', value: '2016-05-04'}]"
                    :filter-method="filterHandler">
				</el-table-column>
				<el-table-column
					align="center"
					prop="personIdText"
					label="预警老人">
				</el-table-column>
				<el-table-column
                    show-overflow-tooltip
					prop="alarmType"
					align="center"
					label="预警类型"
					:filters="[]"
					:filter-method="filterHandler"
					filter-placement="bottom-end">
				</el-table-column>
                <!-- <el-table-column show-overflow-tooltip
					align="center"
					prop="alarmType"
					label="预警内容">
				</el-table-column> -->
                <el-table-column
					prop="processStatus"
					align="center"
					label="处理状态"
					:filters="[]"
					:filter-method="filterHandler"
					filter-placement="bottom-end">
                    <template slot-scope="scope">
						<span v-if="scope.row.processStatus==0">无需处理</span>
         				<span v-if="scope.row.processStatus==1">暂不处理</span>
          				<span v-if="scope.row.processStatus==2">处理中</span>
          				<span v-if="scope.row.processStatus==3">已处理</span>
					</template>
				</el-table-column>
			</el-table>
        </div>
        <div v-show="optionType == 2" class="option-two">
           <el-table
				ref="remarkTable2"
				:data="oldList"
				:max-height="300"
				style="width: 100%">
				<el-table-column 
					align="center"
					prop="name"
					label="老人姓名">
				</el-table-column>
				<el-table-column
					prop="documentNumber"
					align="center"
					label="证件号码"
                    show-overflow-tooltip>
				</el-table-column>
				<el-table-column
					prop="residenceSubdistrict"
					align="center"
					label="户籍地址"
                    show-overflow-tooltip
					>
                    <template slot-scope="scope">
                        <p>{{scope.row.residenceSubdistrict}}{{scope.row.residenceDetail}}</p>
                    </template>
				</el-table-column>
                <!-- <el-table-column show-overflow-tooltip
					align="center"
					prop="contact"
					label="紧急联系人">
				</el-table-column> -->
                <el-table-column
					prop="phone"
					align="center"
					label="联系电话"
                    show-overflow-tooltip
					>
				</el-table-column>
				</el-table>
        </div>
    </div>
</template>
<script>
// import {personEventList, personDeviceList} from "../api/indexApi";
import {getSingleList,getSinglePeople} from "../api/oldmanApi"
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            optionType: 2,
            warningType: '',
            warningHandingDetails: [
                {time: '2023-08-26',name: '张克金',type: 'SOS报警',content: '这是预警内容,这是预警内容',processStatus: 0,},
                {time: '2023-08-22',name: '张克金',type: 'SOS报警',content: '意外触发报警',processStatus: 1,},
            ],
			oldList: [
                {name:'杨昌孝',documentNumber:'511027194409044811',address:'普安乡普照寺街56号',contact:'杨阳',phone:'13898297035'},
                // {name:'杨宗明',documentNumber:'511027195111264716',address:'普安乡普照寺街56号',contact:'杨雪',phone:'13698291063'},
                // {name:'曾国治',documentNumber:'511027195305184714',address:'普安乡普照寺街56号',contact:'曾乾华',phone:'15171245624'},
                // {name:'杨德水',documentNumber:'51102719340504471x',address:'普安乡普照寺街56号',contact:'杨昊',phone:'18171247499'},
                // {name:'李正华',documentNumber:'511027193912144715',address:'普安乡普照寺街56号',contact:'李天明',phone:'13698793035'},
                // {name:'蒋大彬',documentNumber:'511027194803014713',address:'普安乡普照寺街56号',contact:'将淼淼',phone:'13602327458'},
                // {name:'邱国全',documentNumber:'511027195412064718',address:'普安乡普照寺街56号',contact:'邱敏',phone:'13568458521'},
                // {name:'李明华',documentNumber:'511027194903204717',address:'普安乡普照寺街56号',contact:'李天明',phone:'13654789631'},
                // {name:'陈玉华',documentNumber:'511027195603074732',address:'普安乡普照寺街56号',contact:'李建军',phone:'15165401191'},
                // {name:'杨昌汉',documentNumber:'511027194307074717',address:'普安乡普照寺街56号',contact:'杨廷才',phone:'13648317471'},
            ],
            pageNum: 1,
            pageNum2: 1,
            pageSize: 10,
            totalNum: 0,
            totalNum2: 0,
            info: {},
            // 弹窗类型
            type: 1, // 1.敬老院 2.社区
            id: 0,
        }
    },
    watch: {
        
    },
    mounted() {
        let that = this
        this.$refs.remarkTable.bodyWrapper.addEventListener('scroll', function () {
            let clientHeight = this.clientHeight
            let scrollTop = Math.ceil(this.scrollTop)
            let scrollHeight = this.scrollHeight
            if (clientHeight + scrollTop === scrollHeight) {
                // 滚动到最底时，加载下一页
                // if (that.total <= that.index && !this.popover) return
                // that.index += 1
                // 滚动条回滚至顶部
                // this.$refs.screenTable.bodyWrapper.scrollTop = 0
                if(that.pageNum < that.totalNum) {
                    that.pageNum += 1
                    that.getSingleList()
                }
            }
        }, true)
        this.$refs.remarkTable2.bodyWrapper.addEventListener('scroll', function () {
            let clientHeight = this.clientHeight
            let scrollTop = Math.ceil(this.scrollTop)
            let scrollHeight = this.scrollHeight
            if (clientHeight + scrollTop === scrollHeight) {
                // 滚动到最底时，加载下一页
                // if (that.total <= that.index && !this.popover) return
                // that.index += 1
                // 滚动条回滚至顶部
                // this.$refs.screenTable.bodyWrapper.scrollTop = 0
                if(that.pageNum2 < that.totalNum2) {
                    that.pageNum2 += 1
                    that.getSinglePeople()
                }
            }
        }, true)
    },
    methods: {
        init(info,arr) {
            this.info = info
            this.optionType = 2
            this.type = info.type
            this.pageNum = 1
            this.id = info.id
            this.getSingleList()
            this.getSinglePeople()
        },
        // 获取预警信息
        getSingleList() {
            getSingleList({
                type: this.type,
                id: this.id,
                page: this.pageNum,
                limit: this.pageSize
            }).then(res => {
                console.log(res)
                if(res.code == 0) {
                    if(this.pageNum == 1) this.warningHandingDetails = []
                    this.warningHandingDetails = this.warningHandingDetails.concat(res.page.list) 
                    this.totalNum = res.page.totalPage
                }else {
                    this.warningHandingDetails = []
                }
            })
        },
        // 获取老人信息
        getSinglePeople() {
            getSinglePeople({
                type: this.type,
                id: this.id,
                page: this.pageNum2,
                limit: this.pageSize
            }).then(res => {
                console.log(res)
                if(res.code == 0) {
                    if(this.pageNum2 == 1) this.oldList = []
                    this.oldList = this.oldList.concat(res.page.list)
                    this.totalNum2 = res.page.totalPage
                }else {
                    this.oldList = []
                }
            })
        },
        rowClick(row, column, event) {
			// this.$emit('openRemark',row)
		},
        optionClick(val) {
            this.optionType = val
        },
        closePop() {
            this.$emit('update:show',false)
        },
        option() {},
        filterHandler(value, row, column) {
            const property = column['property'];
            return row[property] === value;
        },
        optioncommunity() {},
        getImageUrl(deviceType) {
            let imageUrl = '/image/equipment/gw.jpg'
            if (deviceType == '17') {
                imageUrl = '/image/equipment/men.png'
            } else if (deviceType == '18') {
                imageUrl = '/image/equipment/water.png'
            } else if (deviceType == '19') {
                imageUrl = '/image/equipment/redline.png'
            } else if (deviceType == '20') {
                imageUrl = '/image/equipment/gas.png'
            } else if (deviceType == '49') {
                imageUrl = '/image/equipment/sos.png'
            } 
            return imageUrl
        }
    },
}
</script>
<style lang="scss">
    .remark-main {
        width: 500px;
        // height: 100px;
        background: rgba(1,31,17,.8);
        position: absolute;
        border: 1px solid #2BCEFD;
        box-sizing: border-box;
        padding: 10px;
        top: 200px;
        left: 712px;
        z-index: 999;
        .border-color:nth-child(1),.border-color:nth-child(2), .border-color:nth-child(3){
            position: absolute;
            // top: 0;
            left: -2px;
            display: block;
            // height: 60px;
            width: 4px;
            background: #2BCEFD ;
        }
        .border-color:nth-child(1) {
            height: 60px;
            top: 0;
        }
        .border-color:nth-child(2) {
            height: 30px;
            top: 90px;
        }
        .border-color:nth-child(3) {
            height: 60px;
            bottom: 0;
        }
        .border-color:nth-child(4) {
            display: block;
            background: #2BCEFD;
            height: 4px;
            width: 30px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .close {
            display: block;
            position: absolute;
            background: rgba(40,70,140,.8);
            top: -20px;
            right: -2px;
            color: white;
            cursor: pointer;
        }
        .title {
            width: 80%;
            background: rgb(15,135,255);
            color: white;
            text-align: left;
            box-sizing: border-box;
            padding: 5px;
            padding-left: 10px;
        }
        .info {
            width: 100%;
            color: white;
            font-size: 12px;
            box-sizing: border-box;
            padding: 10px 10px 0 10px;
            // padding-left: ;
            text-align: left;
        }
        .option {
            display: flex;
            margin-top: 20px;
            .option-item {
                font-size: 12px;
                color: #fff;
                padding: 5px 0px;
                background: rgba(0,131,255,.23);
                width: 60px;
                text-align: center;
                cursor: pointer;
            }
            .option-item-active {
                border: 2px solid rgba(15,135,255,.8);
            }
        }
        .option-one {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            height: calc(100% - 40px);
            box-sizing: border-box;
            flex-wrap: wrap;
            padding: 30px 10px;
            padding-bottom: 30px;
            background: rgba(0,131,255,.23);
            .item-main-top {
          width: 100%;
          box-sizing: border-box;
          padding: 0 20px;
          display: flex;
          height: 30px;
          justify-content: space-between;
          .item-main-top-item {
            width: 24%;
            background: rgba(0,86,255,.6);
            font-size: 12px;
            display: flex;
            align-items: center;
            color: white;
            justify-content: center;
            .el-select {
                .el-input {
                    .el-input__inner {
                        background: none;
                        color: white;
                        text-align: center;
                        border: none;
                        padding-left: 5px;
                        padding-right: 15px;
                        font-size: 12px;
                    }
                    .el-input__suffix {
                        right: -2px;
                    }
                }
                input::-webkit-input-placeholder{
                    color:#fff;
                }
                input::-moz-placeholder{
                    color:#fff;
                }
                input:-ms-input-placeholder{
                    color:#fff;
                }
            }
            .el-date-editor {
                .el-range-separator {
                    color: #fff;
                }
            }
          }
        }
        .item-main-info {
          width: 100%;
          box-sizing: border-box;
          padding: 0 20px ;
          padding-top: 10px;
          // height: calc(100% - 18% - 10px);
          height: 100%;
          overflow: auto;
          scrollbar-width: none;
          max-height: 310px;
          &::-webkit-scrollbar {
            display: none;
          }
          .item-main-info-item {
            height: 30px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            -ms-overflow-style: none;
            div {
              width: 24%;
              font-size: 12px;
              // display: flex;
              // align-items: center;
              color: white;
              // justify-content: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 20px;
              cursor: pointer;
            }
            .unhandled {
              color: red;
            }
            .pending {
              color: rgb(15,135,255);
            }
          }
          .item-main-info-item:nth-child(2n) {
              background: rgba(0,131,255,.25);
          }
        }
        }
        .option-two {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            box-sizing: border-box;
            flex-wrap: wrap;
            padding: 30px 10px;
            padding-bottom: 30px;
            background: rgba(0,131,255,.23);
            .option-item {
                background: #fff;
                border-radius: 10px;
                padding: 20px;
                width: 32%;
                margin-right: 2%;
                margin-bottom: 10px;
                color: #333;
                box-sizing: border-box;
                .option-item-name {
                    margin-top: 5px;
                    font-size: 14px;
                    text-align: left;
                    font-weight: bold;
                }
                .option-item-status {
                    margin-top: 5px;
                    font-size: 12px;
                    color: #999;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    span:nth-child(1) {
                        display: inline-block;
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        background: green;
                        margin-right: 10px;
                    }
                }
            }
            .option-item:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
		.device-image {
			width: 80px;
			height: 80px;
		}
    .el-table__header-wrapper{
        padding:0 !important;
        line-height: 30px !important;
    }
</style>