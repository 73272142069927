<template>
	<div class="main">
		<div class="main-top">
			<div class="main-top-title">
				<!-- 民政养老服务管理系统 -->
				<img src="../image/logo2.png" alt="" />
			</div>
			<div class="main-top-option">
			</div>
			<div class="main-top-time">
				<div class="newTime">
					<div>
						{{ newDay }}
						<span style="margin-left: 10px">{{ newTime }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 地标筛选 -->
		<!-- <div class="remark-option">
            <el-select
                v-model="value"
                filterable
				clearable 
                remote
                reserve-keyword
                placeholder="请输入区"
                :remote-method="remoteMethod"
                :loading="loading">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <el-select
                v-model="value2"
                filterable
				clearable 
                remote
                reserve-keyword
                placeholder="请输入街道"
                :remote-method="remoteMethod2"
                :loading="loading">
                <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </div> -->
		<div class="main-left">
			<div class="item" style="height: 25%;">
				<div class="item-title">机构概况统计</div>
				<div class="item-main row">
					<div class="content-item">
						<div class="bg">
							<img src="../image/oldman/total1.png">
							<span>{{institutionInfo.total}}</span>
						</div>
						<p class="title">机构总数</p>
					</div>
					<div class="content-item">
						<div class="bg">
							<img src="../image/oldman/total2.png">
							<span>{{institutionInfo.nursingHome}}</span>
						</div>
						<p class="title">智慧养老院</p>
					</div>
					<div class="content-item">
						<div class="bg">
							<img src="../image/oldman/total2.png">
							<span>{{institutionInfo.community}}</span>
						</div>
						<p class="title">智慧养老综合体</p>
					</div>
				</div>

			</div>
			<div class="item" style="height:25%">
				<div class="item-title item-title2">机构设施设备统计</div>
				<div class="institution">
					<div v-for="(item,index) in institutionList" @click="detailClick" :key="index"
						class="institution-item">
						<div class="institution-item-left">
							{{item.name}}
						</div>
						<div class="institution-item-right">
							<div class="institution-item-right-item">
								<span>{{item.type==1 ? '敬老院床位':'村社数量'}}</span>
								<span>{{item.bedNum + (item.type==1 ? '张' : '个')}}</span>
							</div>
							<div class="institution-item-right-item">
								<span>现有老人</span>
								<span>{{item.people}}人</span>
							</div>
							<div class="institution-item-right-item">
								<span>设备在线率</span>
								<span>{{item.onlineRate}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="item" style="height:22%;">
				<div class="item-title item-title2">老人状况统计</div>
				<div class="item-chart">
					<polyline-chart type="pie" :boxHeight="pieHeight" :series="oldStatusList" :pieCenter="['25%','50%']"
						:pieRadius="[40,50]" :simple="true" :legendShow="true" :pieCenterShow="true" :color="oldColors"
						:seriesItemStyle="pieBorder" :pieCenterText="oldCenterText"></polyline-chart>
				</div>
				<div class="item-chart-bg"></div>
			</div>
			<div class="item" style="height:28%">
				<div class="item-title">报警频次统计</div>
				<div class="time-option">
					<div @click="optionClick(1)" :class="option == 1 ? 'option-item active' : 'option-item'">近24小时</div>
					<div @click="optionClick(2)" :class="option == 2 ? 'option-item active' : 'option-item'">近一周</div>
				</div>
				<div class="item-chart">
					<!-- <pie-chart :chartHeight="15" :chartWidth="100" chart :data="rankList"></pie-chart> -->
					<polyline-chart :series="warningList" :boxHeight="20" :legendShow="true" :gridTop="60"
						:color="pieColors"></polyline-chart>
				</div>
			</div>
		</div>
		<div class="main-right">
			<div class="item" style="height: 160px">
				<div class="item-title">今日事件总揽</div>
				<div class="today">
					<div class="today-num">
						<div class="today-title">今日事件</div>
						<img src="../image/yujing.png" alt="">
						<span>{{todayInfo.total}}</span>
					</div>
					<div class="today-num">
						<div class="today-title">已处理</div>
						<img src="../image/yujing.png" alt="">
						<span>{{todayInfo.processed}}</span>
					</div>
					<div class="today-num">
						<div class="today-title">待处理</div>
						<img src="../image/yujing.png" alt="">
						<span>{{todayInfo.pending}}</span>
					</div>
				</div>
			</div>
			<div class="item" style="height: 36%">
				<div class="item-title">报警类型占比</div>
				<div class="item-chart">
					<polyline-chart type="rosePie" :boxHeight="28" :series="typeList" :pieRadius="[20,80]"
						:color="pieColors"></polyline-chart>
				</div>
			</div>
			<div class="item item-record" style="height: 43%; overflow: auto;">
				<div class="item-title">报警记录</div>
				<div class="item-main" style="padding-top: 0px; cursor: pointer;">
					<el-table ref="filterTable" :data="recordList" :max-height="tableHeight" @row-click="rowClick"
						style="width: 100%">
						<el-table-column show-overflow-tooltip prop="alarmTime" align="center" label="时间">
							<!-- <template slot="header" slot-scope="scope">
						<span>事件</span>
					</template> -->
							<!-- <template slot-scope="scope">
						{{scope.row.visitTime.substr(5, 11)}}
					</template> -->
						</el-table-column>
						<el-table-column align="center" prop="personIdText" label="老人">
						</el-table-column>
						<!-- <el-table-column show-overflow-tooltip
					align="center"
					prop="detailAddress"
					label="地址">
				</el-table-column> -->
						<el-table-column show-overflow-tooltip prop="alarmType" align="center" label="预警类型"
							:filters="[]" :filter-method="filterHandler" filter-placement="bottom-end">
						</el-table-column>
						<el-table-column prop="processStatus" align="center" label="处理状态" :filters="[]"
							:filter-method="filterHandler" filter-placement="bottom-end">
							<template slot-scope="scope">
								<span v-if="scope.row.processStatus==0">
									<span v-if="scope.row.isResume==1">已恢复</span>
									<span v-else>待处理</span>
								</span>
								<span v-if="scope.row.processStatus==1">暂不处理</span>
								<span v-if="scope.row.processStatus==2">处理中</span>
								<span v-if="scope.row.processStatus==3">已处理</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>

		<div id="allmap"></div>
		<!-- 详情弹窗 -->
		<old-remark-vue ref="remarkerDetails" :show.sync="detailsShow" @openRemark="openRemark"></old-remark-vue>
		<!-- 报警弹窗 -->
		<marker-warning :show.sync="warningShow" ref="markerWarning" @processEnded="processEnded"></marker-warning>
		<old-alarm ref="oldAlarm" :show.sync="alarmShow"></old-alarm>
	</div>

</template>

<script>
	import * as echarts from "echarts";
	import polylineChart from "../components/polyline-chart.vue";
	import pieChart from "../components/pie-chart.vue";
	import oldRemarkVue from '../components/old-remark.vue';
	import markerWarning from "../components/marker-warning.vue";
	import oldAlarm from '../components/old-alarm.vue'
	import {
		getTotal,
		getReport,
		getTodayEvent,
		getEventList,
		getSelfCare,
		getRemark,
		getPersonGuardianList
	} from "../api/oldmanApi"
	export default {
		components: {
			polylineChart,
			pieChart,
			oldRemarkVue,
			markerWarning,
			oldAlarm
		},
		data() {
			return {
				map: "",
				newDay: "2022.10.23",
				newTime: "18:25:53",
				color: [],
				colorCross: [],
				visitListData: [{
					visitTime: '2023-12-12 12:12:12',
					personIdText: '张克金',
					detailAddress: '新市街道',
					visitType: '现场'
				}],
				visitTypeReportData: [{
					name: '1',
					value: 12
				}, {
					name: '2',
					value: 23
				}, {
					name: '3',
					value: 34
				}, {
					name: '4',
					value: 45
				}],
				recordList: [
					// {alarmTime: '',personIdText: '张克金',detailAddress: '普安乡普照寺街56号',subName: 'SOS报警',status: 0,alarmType: '老人触发',phone: '13895763201'},
					// {alarmTime: '',personIdText: '张克金',detailAddress: '普安乡普照寺街56号',subName: 'SOS报警',status: 1,alarmType: '意外触发报警',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-02',personIdText: '杨昌孝',detailAddress: '普安乡普照寺街56号',subName: '水侵探测器',status: 1,alarmType: '水龙头未关',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-02',personIdText: '曾长清',detailAddress: '普安乡永明村4组',subName: '人体移动传感器',status: 1,alarmType: '出门移动',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-02',personIdText: '杨昌汉',detailAddress: '普安乡普照寺街56号',subName: '人体移动传感器',status: 1,alarmType: '出门移动',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-02',personIdText: '蒋大彬',detailAddress: '普安乡普照寺街56号',subName: '门窗传感器',status: 1,alarmType: '养护人员开启',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-02',personIdText: '唐志明',detailAddress: '普安乡永明村10组',subName: '门窗传感器',status: 1,alarmType: '大风意外开启',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-02',personIdText: '樊高宽',detailAddress: '普安乡永明村5组',subName: 'SOS报警器',status: 1,alarmType: '老人触发',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-01',personIdText: '蒋大彬',detailAddress: '普安乡普照寺街56号',subName: '水侵探测器',status: 1,alarmType: '水龙头未关',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-01',personIdText: '陈玉华',detailAddress: '普安乡普照寺街56号',subName: '人体移动传感器',status: 1,alarmType: '老人触发',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-01',personIdText: '杨德水',detailAddress: '普安乡普照寺街56号',subName: 'SOS报警器',status: 1,alarmType: '老人触发',remarks: '护理人员已处理'},
					// {alarmTime: '2023-09-01',personIdText: '李绪章',detailAddress: '普安乡永明村5组',subName: '人体移动传感器',status: 1,alarmType: '老人移动触发',remarks: '护理人员已处理'},
				],
				// table高度
				tableHeight: 0,
				typeList: [{
						name: 'SOS报警器',
						value: 20,
					},
					{
						name: '门窗传感器',
						value: 10,
					},
					{
						name: '人体移动传感器',
						value: 20,
					},
					{
						name: '水侵探测器',
						value: 5,
					},
				],
				pieColors: ['#5c5cf8', '#f0872b', '#15cbfb', '#1bc7a1', '#fcc44a', '#175df0'],
				option: 1,
				detailsShow: false,
				value: '',
				// table表格数据
				loading: false,
				pageNum: 1,
				pageSize: 10,
				totalNum: 0,
				// 排名信息
				rankList: [{
						name: '养老机构一',
						value: 20
					},
					{
						name: '养老机构二',
						value: 15
					},
					{
						name: '养老机构三',
						value: 15
					},
					{
						name: '养老机构四',
						value: 10
					},
				],
				// 老人状况统计
				oldStatusList: [{
						name: '自理老人',
						value: 60,
						percent: '71.5%'
					},
					{
						name: '半失能老人',
						value: 16,
						percent: '19%'
					},
					{
						name: '失能老人',
						value: 8,
						percent: '9.5%'
					},
				],
				oldColors: ['#ffc837', '#35c1cf', '#0083ff'],
				// 饼状图距离
				pieBorder: {
					borderRadius: 10,
					borderWidth: 5,
				},
				// 环形中间文字
				oldCenterText: '84/人',
				warningList: [{
						type: 'line',
						name: 'sos报警器',
						data: [20, 30, 21, 10, 5, 24, 34]
					},
					{
						type: 'line',
						name: '门窗传感器',
						data: [10, 21, 16, 0, 15, 4, 24]
					},
					{
						type: 'line',
						name: '人体移动传感器',
						data: [20, 6, 55, 56, 25, 4, 36]
					},
					{
						type: 'line',
						name: '水侵探测器',
						data: [15, 20, 16, 2, 15, 24, 16]
					},
				],
				// 机构数据
				institutionList: [],
				// 机构数量统计
				institutionInfo: {
					total: 2,
					nursingHome: 1,
					community: 1,
				},
				// 今日事件
				todayInfo: {
					total: 2,
					processed: 1,
					pending: 1,
				},
				// 饼状图大小
				pieHeight: 0,
				warningShow: false,
				alarmShow: false
			}
		},
		created() {
			this.m_mapLoader().then((AMap) => {
				this.initMap();
				this.addMarker()
				this.getTotal()
				this.getReport()
				this.getSelfCare()
				this.getTodayEvent()
				this.getEventList()
			});
			setInterval(() => {
				this.getDateTime();
			}, 1000);
			setTimeout(() => {
				this.$topGetToken(token => {
					console.log('========')
					console.log(token)
				})
			}, 3000)
			setTimeout(() => {
				this.$topGetBigScreenPermissions(pers => {})
			}, 5000)
			// this.createColors()
			window.addEventListener("resize", this.siteHeight);
			this.siteHeight()
			// let yy = new Date().getFullYear();
			// let mm = (new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1);
			// let dd = new Date().getDate() > 9 ? new Date().getDate() : '0' + new Date().getDate();
			// this.recordList[0].alarmTime = yy + '-' + mm + '-' + dd
			// this.recordList[1].alarmTime = yy + '-' + mm + '-' + dd 


		},
		mounted() {
			// table表格触底事件
			this.$refs.filterTable.bodyWrapper.addEventListener('scroll', function() {
				let clientHeight = this.clientHeight
				let scrollTop = Math.ceil(this.scrollTop)
				let scrollHeight = this.scrollHeight
				if (clientHeight + scrollTop === scrollHeight) {
					// 滚动到最底时，加载下一页
					// if (that.total <= that.index && !this.popover) return
					// that.index += 1
					// 滚动条回滚至顶部
					// this.$refs.screenTable.bodyWrapper.scrollTop = 0
					if (this.pageNum < this.totalNum) {
						this.pageNum += 1
						// this.getInform()
					}
				}
			}, true)
		},
		methods: {
			optionClick(val) {
				this.option = val
			},
			detailClick(val) {
				this.detailsShow = true
				// this.$refs.remarkerDetails
			},
			processEnded(data) {
				console.log('processEnded', data)
				// this.m_clearWarningMarker(this.map)
				this.recordList[0].status = 1
				this.recordList[0].remarks = data
				this.todayInfo.processed = 2
				this.todayInfo.pending = 0
				this.warningShow = false
			},
			openRemark(row) {
				this.warningShow = true
				this.$refs.markerWarning.init(row, 1)
			},
			rowClick(row, column, event) {
				// this.warningShow = true
				// this.$refs.markerWarning.init(row,1)
			},
			addMarker(obj) {
				// let arr = [
				// 	{ position: [104.566034, 30.402503], name: "禾丰镇敬老院",num: -1},
				// 	{ position: [104.546054, 30.432503], name: "禾丰镇敬老院普安分院",num: -1},
				// ]
				// var obj = { position: [104.566034, 30.402503], name: "禾丰镇敬老院",num: -1};
				// this.m_addMarker(this.map, obj, () => {
				//     this.detailsShow = true;
				// });

				// this.institutionList.forEach((item,index) => {
				// 	item.num = -1
				// 	this.m_addMarker(this.map, item, () => {
				//     	this.detailsShow = true;
				// 		this.$refs.remarkerDetails.init(item,this.recordList)
				// 	});
				// })
			},
			remoteMethod(query) {
				if (query !== '') {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						this.options = this.list.filter(item => {
							return item.label.toLowerCase()
								.indexOf(query.toLowerCase()) > -1;
						});
					}, 200);
				} else {
					this.options = [];
				}
			},
			remoteMethod2(query) {
				if (query !== '') {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						this.options2 = this.list2.filter(item => {
							return item.label.toLowerCase()
								.indexOf(query.toLowerCase()) > -1;
						});
					}, 200);
				} else {
					this.options2 = [];
				}
			},

			siteHeight() {
				setTimeout(() => {
					var hei = document.querySelector(".item-record").clientHeight;
					this.tableHeight = hei - 50
					var bodyHeight = document.documentElement.clientHeight
					this.pieHeight = Number((((bodyHeight - 90) * .22 - 40) / bodyHeight / .01).toFixed(0))
				})
			},
			initMap() {
				let map = this.m_initMap();
				map.on("click", (e) => {
					console.log("click", e);
				});
				this.map = map;

			},
			createColors() {
				this.color.push(new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						offset: 0,
						color: '#188df0'
					},
					{
						offset: 1,
						color: '#83bff6'
					}
				]))
				this.color.push(new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						offset: 0,
						color: '#E6A23C'
					},
					{
						offset: 1,
						color: '#83bff6'
					}
				]))

				this.colorCross.push(new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
						offset: 1,
						color: '#188df0'
					},
					{
						offset: 0,
						color: '#83bff6'
					}
				]))
				this.colorCross.push(new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
						offset: 1,
						color: '#E6A23C'
					},
					{
						offset: 0,
						color: '#83bff6'
					}
				]))
			},
			getDateTime() {
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth() + 1;
				let dd = new Date().getDate();
				let hh = new Date().getHours();
				let ms =
					new Date().getSeconds() < 10 ?
					"0" + new Date().getSeconds() :
					new Date().getSeconds();
				let mf =
					new Date().getMinutes() < 10 ?
					"0" + new Date().getMinutes() :
					new Date().getMinutes();
				this.newDay = yy + "." + mm + "." + dd;
				this.newTime = hh + ":" + mf + ":" + ms;
			},

			filterHandler(value, row, column) {

			},
			// 获取机构统计概况
			getTotal() {
				getTotal().then(res => {
					if (res.code == 0) {
						this.institutionInfo.total = res.total
						this.institutionInfo.nursingHome = res.nursingHome
						this.institutionInfo.community = res.community
					}
				})
			},
			// 获取机构设施设备统计
			getReport() {
				getReport().then(res => {
					console.log(res)
					if (res.code == 0) {
						this.institutionList = res.list
						getRemark().then(res => {
							console.log(res)
							if (res.code == 0) {
								this.institutionList.forEach(item => {
									let arr = res.list.filter(o => o.name == item.name)
									if (arr.length > 0) {
										item.position = [arr[0].lng, arr[0].lat]

										item.type = arr[0].type
										item.id = arr[0].id
										item.phone = arr[0].phone
										item.address = arr[0].address
									}
								})
								this.institutionList.forEach((item, index) => {
									item.num = -1
									if (item.position) {
										this.m_addMarker(this.map, item, () => {
											this.detailsShow = true;
											this.$refs.remarkerDetails.init(item, this
												.recordList)
										});
									}

								})
							}
						})
					}
				})
			},
			// 获取老人状况统计
			getSelfCare() {
				getSelfCare().then(res => {
					console.log(res)
					if (res.code == 0) {
						let num = 0
						res.list.forEach((item, index) => {
							item.percent = item.percent.toFixed(2)
							num += item.value
						})
						this.oldStatusList = res.list.map((item, index) => {
							item.percent = item.percent + '%'
							return item
						})
						this.oldCenterText = num + '/人'
					}
				})
			},
			// 获取今日事件总揽
			getTodayEvent() {
				getTodayEvent().then(res => {
					console.log(res)
					if (res.code == 0) {
						this.todayInfo = res.data
					}
				})
			},
			// 获取报警记录
			getEventList() {
				getEventList().then(res => {
					console.log(res)
					if (res.code == 0) {
						let list = res.page.list
						list.forEach(item => {
							item.position = [item.lng, item.lat]
							item.num = item.eventCount
							item.name = item.personIdText
							this.m_addMarker(this.map, item, () => {
								// 获取用户信息
								getPersonGuardianList({'personId':item.personId}).then(res => {
									if (res.code == 0) {
										let guardianList = res.info
										
										this.alarmShow = true
										this.$refs.oldAlarm.init({
											'guardianName': guardianList && guardianList.length ? guardianList[0].name : '',
											'guardianPhone': guardianList && guardianList.length ? guardianList[0].phone : '',
											'name': item.personIdText,
											'address': item.address,
											'personId': item.personId
										})
									}
								})

							});
						})
						this.recordList = list
					}
				})
			},
			// 获取中心点地标
		}
	}
</script>

<style lang="scss">
	@import "../assets/css/index.scss";
	@import "../assets/css/oldman.scss";
	@import "../assets/css/element-ui.css";
</style>
