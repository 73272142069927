import Ajax from './ajax'

const getTotal = () => {
    return Ajax({
        url: '/community/big-screen/total'
    })
}

const getReport = () => {
    return Ajax({
        url: '/community/big-screen/report'
    })
}

const getSelfCare = () => {
    return Ajax({
        url: '/community/big-screen/self-care'
    })
}

const getTodayEvent = () => {
    return Ajax({
        url: '/community/big-screen/today-event'
    })
}

const getEventList = (data) => {
    return Ajax({
        url: '/community/big-screen/event-list',
        data: data,
		method: 'POST'
    })
}

const getRemark = () => {
    return Ajax({
        url: '/community/big-screen/organization-list'
    })
}

const getSingleList = (data) => {
    return Ajax({
        url: '/community/big-screen/event-list-single',
        // params: params
        data: data,
		method: 'POST'
    })
}

const getSinglePeople = (data) => {
    return Ajax({
        url: '/community/big-screen/person-list-single',
        // params: params
        data: data,
		method: 'POST'
    })
}

const getPersonEventList = (data) => {
	return Ajax({
		url: '/community/communityalarm/alarm-new-list',
		params: data,
		method: 'GET'
	})
}
const getPersonGuardianList = (data) => {
	return Ajax({
		url: '/community/communityperson/guardian-info',
		params: data,
		method: 'GET'
	})
}

export {
    getTotal,
    getReport,
    getTodayEvent,
    getEventList,
    getSelfCare,
    getRemark,
    getSingleList,
    getSinglePeople,
		getPersonEventList,
		getPersonGuardianList
}